.hidden {
  display: none;
}

.stage,
.overview {
  position: relative;
  background-color: #000;
  overflow: hidden;
  flex-grow: 1;
  margin: 5px;
}

.panel,
.channel {
  position: absolute;
  user-select: none;
  display: flex;
}

.panel > div,
.text-layer-wrapper {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.panel-image {
  background-size: cover;
  background-position: center;
  transition: 0.5s;
}

.panel-video::-webkit-scrollbar,
.panel-video > div::-webkit-scrollbar {
  display: none;
}

.panel-video,
.panel-video > div {
  -ms-overflow-style: none;
  transition: 0.5s;
}

.panel-video video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.webcam {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.media-caption {
  border-radius: 4px;
  padding: 5px 8px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  background-color: rgba(50, 50, 50, 0.7);
  color: #ccc;
  max-width: 240px;
  text-shadow: 1px 1px #222;
  transition: opacity 0.5s;
}

.media-caption .caption-title {
  font-weight: bold;
}

.panel-audio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.panel-audio audio {
  width: 90%;
}

.text-layer {
  font-size: 3vmax;
  text-align: center;
  line-height: 100%;
  transition: opacity 0.5s, color 0.5s;
  width: auto !important;
  height: auto !important;
  display: inline-block;
}

.text-layer p:last-child {
  margin-bottom: 0;
}

.slabtype div {
  line-height: 100%;
  white-space: nowrap;
  margin: 20px 0;
}

.disable-interaction {
  pointer-events: none;
}

.enable-interaction {
  pointer-events: all !important;
}

.cQMUqH,
.cDBSwk {
  cursor: pointer;
  z-index: 1000;
}
.cQMUqH,
.cQMUqH .square,
.cDBSwk,
.cDBSwk .square {
  border-color: yellow !important;
}

.panel-info {
  position: relative;
  display: inline-block;
  font-size: 10px;
  background-color: yellow;
  border-radius: 4px;
  margin: 5px 0 0 5px;
  color: black;
  padding: 5px;
  font-weight: bold;
  letter-spacing: -0.5px;
  z-index: 1000;
}

.unselected-panel {
  position: absolute;
  display: inline-block;
  z-index: 1;
}

.unselected-panel:hover {
  border: 1px solid #2777b5;
  cursor: pointer;
}
