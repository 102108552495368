#activation-form {
  position: absolute;
  top: 40px;
  left: calc(50% - 250px);
  width: 500px;
}
#instructions strong {
  color: #4c90f0;
}
#code-input {
  font-size: 36px !important;
  height: 50px;
  line-height: 50px;
}
.auth-code {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 48px;
  text-align: center;
}
