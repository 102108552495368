@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';

@font-face {
  font-family: 'Bravura Text';
  src: local('BravuraText'), url(./fonts/BravuraText.woff2) format('woff2');
}

body {
  background-color: black;
  color: white;
}

hr {
  border-color: #5c7080;
}

.bp3-overlay,
.bp3-overlay-content,
.bp3-overlay-backdrop {
  z-index: 1500;
}

/*.bp3-overlay.opener > .bp3-overlay-backdrop {
  background-image: url('/images/steve-johnson-rCIrPsSubLY-unsplash.jpg');
  background-size: cover;
  background-position: center;
  opacity: .25;
}*/

.app-column {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.no-scroll {
  -mx-overflow-style: none;
  scrollbar-width: none;
}
.no-scroll::-webkit-scrollbar {
  display: none;
}

.bp3-navbar .image-btn {
  padding: 5px 0;
}

.menu-bar {
  align-items: stretch;
}

.menu-bar .logo {
  height: 24px;
  cursor: pointer;
}

/*.splash-logo {
  width: 100%;
  height: 100%;
  background-image: url("/images/stepworks-logo-dark.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 450px 100px;
}*/

.about-content {
  text-align: center;
}

.about-content .logo {
  height: 48px;
}

.splash-overlay {
  left: calc(50vw - 250px);
  margin: 10vh 0;
  top: 0;
  width: 500px;
  font-size: 18px;
  color: #ced9e0;
}

@media only screen and (max-width: 600px) {
  .splash-overlay {
    left: 0;
    width: 100%;
    margin: 0;
    padding: 10px;
  }
}

.splash-overlay h1 {
  color: #bfccd6 !important;
}

.splash-overlay .aux-text {
  font-size: 14px;
}

.splash-overlay a {
  color: inherit;
  text-decoration: underline;
}

.splash-overlay .title {
  text-shadow: 1px 1px black;
  color: white;
}

.footer button {
  margin: 0 !important;
}

.splash-overlay strong {
  color: white;
}

.welcome {
  position: absolute;
  left: 50%;
  margin-left: -220px;
  width: 440px;
  height: 240px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px;
  color: #fff;
  top: 50%;
  margin-top: -140px;
  border-radius: 10px;
}

.welcome .app-info {
  position: absolute;
  left: 30px;
  bottom: 20px;
}

.welcome .app-launch {
  position: absolute;
  right: 30px;
  bottom: 20px;
}

.message {
  position: absolute;
  left: 50%;
  margin-left: -200px;
  width: 400px;
  padding: 0 20px;
  color: #aaa;
  background-color: rgb(0.25, 0.25, 0.25);
  opacity: 0.75;
  top: 30%;
  margin-top: -50px;
  border-radius: 10px;
  text-align: center;
  z-index: 999;
}

.story-container {
  height: 300px;
  overflow-y: auto;
  border: 1px solid #3a4b59;
}

.story-row {
  border-bottom: 1px solid #3a4b59;
  padding: 10px;
  cursor: pointer;
}

.story-row:hover {
  background-color: #202b33;
}

.stage-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-grow: 1;
  user-select: none;
  height: calc(100% - 50px);
}

.stage-wrapper {
  flex-grow: 1;
  display: flex;
}

.stage-wrapper.locked {
  border: 1px solid #666;
  flex-grow: 0;
}

.play-bar {
  position: absolute;
  bottom: 0;
  margin: 15px;
  width: calc(100% - 30px);
  border-radius: 4px;
  opacity: 0.9;
  transition: opacity 0.5s;
}

.bp3-tabs {
  display: flex;
  flex-direction: column;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.fade-out {
  opacity: 0;
}

.main-view-col {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  /*display: flex;
  flex-direction: column;*/
}

.main-view-col.reduced {
  width: calc(100% - 450px);
}

/*.stage, .overview {
  position: relative;
  background-color: #000;
  overflow: hidden;
  flex-grow: 1;
  margin: 5px;
}*/

#media-status {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.sidebar {
  flex-basis: 33%;
}

/*.panel, .channel {
  position: absolute;
  user-select: none;
  display: flex;
}

.panel > div, .text-layer-wrapper {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}*/

/*.text-layer-wrapper {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}*/

/*.disable-interaction {
  pointer-events: none;
}*/

/*.enable-interaction {
  pointer-events: all !important;
}*/

/*.panel-image {
  background-size: cover;
  background-position: center;
  transition: .5s;
}*/

/*.panel-audio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.panel-audio audio {
  width: 90%;
}*/

/*.webcam {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}*/

/*.text-layer {
  /*display: flex;
  align-items: center;
  justify-content: center;*
  font-size: 3vmax;
  text-align: center;
  line-height: 100%;
  /*pointer-events: none;*
  transition: opacity .5s, color .5s;
  /*z-index: 10;*
  margin: 20px;
  width: auto !important;
  height: auto !important;
  display: inline-block;
}

.text-layer p:last-child { margin-bottom: 0; }*/

/*.text-layer.slabtype {
  width: 100% !important;
  height: 100% !important;
}*/

/*.slabtype div {
  line-height: 100%;
  white-space: nowrap;
  margin: 20px 0;
}*/

.editor-highlights {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.panel-editor {
  margin: 10px;
  /*border: 1px solid #6B85FA;*/
  border: 1px solid yellow;
  width: calc(100% - 20px) !important;
  height: calc(100% - 20px) !important;
  z-index: 15;
}

.panel-editor.dragging {
  border-color: yellow;
}

.panel-editor .title {
  position: absolute;
  top: 0;
  padding: 0 5px;
  display: inline-block;
  background-color: yellow;
  color: black;
  font-size: 10px;
  cursor: default;
  user-select: none;
}

.panel-editor.dragging .title,
.panel-editor.dragging .corner {
  background-color: yellow;
}

.panel-editor .corner {
  position: absolute;
  background-color: yellow;
  width: 10px;
  height: 10px;
}

.panel-editor .right {
  right: 0;
}

.panel-editor .top {
  top: 0;
}

.panel-editor .bottom {
  bottom: 0;
}

/*.hidden {
  display: none;
}*/

/*.panel-video::-webkit-scrollbar, .panel-video > div::-webkit-scrollbar {
  display: none;
}

.panel-video, .panel-video > div {
  -ms-overflow-style: none;
  transition: .5s;
}

.panel-video video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}*/

.text-fade-enter {
  opacity: 0.01;
}

.text-fade-enter.text-fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.text-fade-leave {
  opacity: 1;
}

.text-fade-leave.text-fade-leave-active {
  opacity: 0.01;
  transition: opacity 100ms ease-in;
  display: none;
}

.measuring {
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap;
  font-size: 10vw;
}

.measuring.ar {
  font-size: 128px;
}

.text-fit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.track-name {
  display: inline-block;
  width: 104px;
  margin-left: 8px;
  margin-right: 5px;
  font-weight: bold;
}

/* begin column editor */

.column-editor {
  position: relative;
  background-color: #202b33;
  color: #a8b6c2;
  font-size: 14px;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-basis: 320px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  display: flex;
}

.editor-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

/* begin state editor */

.float-right {
  float: right;
  margin-right: 0 !important;
}

.state-editor {
  padding: 8px 12px 5px 5px;
  line-height: 30px;
  min-height: 47px;
  border-bottom: 1px solid #394b59;
  text-align: left;
  background-color: #293742;
  white-space: nowrap;
  display: flex;
}

.state-editor .bp3-control {
  margin-bottom: 0;
}

.state-editor .bp3-form-group {
  margin-bottom: 0;
  display: inline-block;
}

.state-editor-controls {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.state-editor .bp3-switch {
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
}

.state-editor-popover {
  padding: 15px;
  display: flex;
  align-items: center;
}

.state-editor-popover .bp3-form-group {
  margin: 0;
  display: inline-block;
}

.bp3-form-group.bp3-inline {
  display: flex;
}

.sequence-editor {
  font-size: 18px;
  padding: 8px 12px 7px 12px;
  line-height: 30px;
  border-bottom: 1px solid #394b59;
  text-align: left;
  background-color: #293742;
}

.sequence-editor > .bp3-button {
  margin-left: -10px;
}

.score-editor {
  width: 100%;
  overflow-x: auto;
  height: 100%;
  overflow-y: auto;
  display: flex;
}

.col-resizer {
  background-color: #394b59;
  min-width: 4px;
  width: 4px;
  flex-shrink: 0;
  cursor: col-resize;
}

.score-column {
  width: 100%;
  max-width: 320px;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #394b59;
}

.score-column-header {
  padding: 12px 12px;
  line-height: 30px;
  font-weight: bold;
  border-bottom: 1px solid #394b59;
}

.score-column .step-controls {
  position: relative;
  bottom: 0;
  padding: 12px 12px;
}

.score-column-body {
  height: 100%;
  width: 320px;
  overflow-y: auto;
}

.score-column table {
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  cursor: pointer;
}

.score-text-editor,
.step-text-editor {
  width: 320px;
}

.score-column tr {
  height: 45px;
}

.step-index-col,
.delay-index-col {
  text-align: center;
  width: 35px;
  border-right: 1px solid #394b59;
  border-bottom: 1px solid #394b59;
  font-weight: normal;
}

.add-col {
  text-align: center;
  width: 35px;
}

.score-column tr.current {
  background-color: #293742;
}

.score-column td {
  border-bottom: 1px solid #394b59;
  border-right: 1px solid #394b59;
  white-space: nowrap;
}

.action-preview {
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.score-column td:last-child {
  border-right: none;
}

#cloudinary_upload_button {
  cursor: pointer;
}

.score-text-editor td,
.step-text-editor td {
  padding: 0 8px;
}

.dragged {
  z-index: 10;
}

.score-text-editor tr.dragged td,
.score-text-editor tr.dragged th,
.step-text-editor tr.dragged th,
.step-text-editor tr.dragged td {
  border-bottom: none;
  cursor: grabbing;
}

.action-editor {
  padding: 4px 0;
  line-height: 34px;
  overflow: visible;
}

#enter-direction-select {
  margin: 4px 0 2px 0;
}

.action-editor .bp3-form-group {
  margin: 4px 0 2px 0;
}

.bp3-html-select,
.bp3-select,
.bp3-form-content {
  line-height: 100%;
}

.score-music-editor {
  border-right: 1px solid #394b59;
}

.score-music-editor th {
  height: 45px;
  text-align: center;
  border-bottom: 1px solid #394b59;
  border-right: 1px solid #394b59;
  background-color: #212b32;
  position: sticky;
  z-index: 1;
}

.score-music-editor tr.current > th {
  background-color: #293742;
}

thead .step-index {
  top: 0;
  left: 0;
  z-index: 2;
}

.score-music-editor thead > tr > th {
  top: 0;
}

.score-music-editor tbody > tr > th {
  left: 0;
}

.score-music-editor td {
  width: 44px;
  padding: 0;
}

.character-avatar {
  display: inline-block;
  border-radius: 12px;
  background-color: #8a9ba8;
  color: #ddd;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-right: 10px;
  vertical-align: middle;
  cursor: pointer;
}

.state-editor .character-avatar {
  margin: 5px;
}

.score-music-editor .character-avatar {
  margin-right: 0;
}

.thumbnail {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.text-thumbnail {
  color: white;
  white-space: nowrap;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-thumbnail.current {
  max-width: inherit;
  overflow: inherit;
  text-overflow: inherit;
}

.visual-media-thumbnail img {
  margin-top: 4px;
  height: 30px;
}

.visual-media-thumbnail.placeholder {
  width: 40px;
  height: 30px;
  border: 1px solid #394b59;
  background-color: black;
}

.score-media-editor {
  width: 100%;
  /*text-align: center;*/
}

.combo-thumbnail {
  width: 100px;
  height: 100px;
  margin: 0;
  border-right: 1px solid #394b59;
  border-bottom: 1px solid #394b59;
  cursor: pointer;
  overflow: hidden;
}

.combo-thumbnail.current {
  background-color: #293742;
  outline: 2px solid white;
  outline-offset: -2px;
}

.combo-thumbnail.current.compact {
  outline: none;
}

.combo-thumbnail.compact {
  width: 45px;
  height: 45px;
  margin: 0;
  border-right: none;
  border-bottom: none;
}

.combo-thumbnail div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 10px;
  color: white;
  text-align: center;
  text-shadow: 1px 1px 1px black;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.combo-thumbnail.compact .bp3-icon {
  position: relative;
  left: calc(50% - 8px);
}

.combo-thumbnail.compact .bp3-icon-plus {
  position: relative;
  left: calc(50% - 13px);
}

.combo-thumbnail.placeholder {
  background-color: black;
}

.enter-thumbnail {
  line-height: 30px;
}

.enter-thumbnail.multi {
  width: 30px;
  height: 30px;
  position: relative;
}

.enter-thumbnail.multi .bp3-icon {
  position: absolute;
}

.enter-thumbnail .top {
  top: 0;
  left: 8px;
}

.enter-thumbnail .bottom {
  top: 16px;
  left: 8px;
}

.enter-thumbnail .left {
  top: 8px;
  left: 0;
}

.enter-thumbnail .right {
  top: 8px;
  left: 16px;
}

/* begin grid editor

.grid-editor {
  position: relative;
  background-color: #202B33;
  color: #6b7983;
  font-size: 14px;
  overflow-y: scroll;
  min-width: 470px;
  width: 470px;
  flex-grow: 0;
  height: 100%;
}

.sequence-editor {
  font-size: 18px;
  padding: 8px 12px;
  border-bottom: 1px solid #394B59;
  text-align: left;
}

.editor-container {
  width: 100%;
  overflow-x: auto;
  height: 100%;
  overflow-y: auto;
  display: flex;
}

.character-score {
  border-right: 1px solid #394B59;
  border-bottom: 1px solid #394B59;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  cursor: pointer;
}

.character-score th, .character-score td {
  margin: 0;
  padding: 0;
}

.character-details {
  background-color: #212b32;
  border-right: 1px solid #394B59;
  position: sticky;
  top: 0;
  z-index: 1;
}

thead .step-index {
  background-color: #2a3741;
  border-right: 1px solid #394B59;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}

.grid-row th.step-index {
  background-color: #212b32;
  border-right: 1px solid #394B59;
  position: sticky;
  left: 0;
  z-index: 1;
}

.grid-row.current th.step-index {
  background-color: #30404d;
}

.stepIndex .cell {
  border-bottom: 1px solid #394B59;
}

.character-name {
  font-size: 24px;
  font-weight: bold;
  color: white;
  padding: 8px 12px;
  white-space: nowrap;
  border-bottom: 1px solid #394B59;
}

.category-header {
  display: flex;
}

.category-header .cell {
  width: 40px;
  height: 40px;
  border-right: 1px solid #394B59;
  border-bottom: 1px solid #394B59;
  text-align: center;
  line-height: 40px;
}

.category-header .cell.text, .grid-row .cell.text {
  width: 160px;
}

.category-header .cell.add, .grid-row .cell.add {
  flex-grow: 1;
}

.category-header .cell:last-child, .grid-row .cell:last-child {
  border-right: none;
}

.character-score td {
  border-right: 1px solid #394B59;
}

.character-score td>div {
  display: flex;
}

.grid-row th>div {
  display: flex;
}

.grid-row:last-child .cell {
  border-bottom: none;
}

.grid-row .cell {
  width: 40px;
  height: 40px;
  border-right: 1px solid #394B59;
  border-bottom: 1px solid #394B59;
  text-align: center;
  line-height: 40px;
  display: table-cell;
}

.grid-row.current {
  background-color: #30404d;
}

.grid-row.current .cell.has-action {
  background-color: black;
}

.grid-row.current .grid-preview {
  color: white;
}

.grid-preview.speak {
  padding-left: 12px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
}

.grid-preview.enter.multi {
  position: relative;
}

.grid-preview.enter.multi .bp3-icon {
  position: absolute;
}

.grid-preview.enter .top {
  top: 2px;
  left: 12px;
}

.grid-preview.enter .bottom {
  top: 22px;
  left: 12px;
}

.grid-preview.enter .left {
  top: 12px;
  left: 2px;
}

.grid-preview.enter .right {
  top: 12px;
  left: 22px;
}

.grid-preview.show-image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

 end grid editor */

/* timeline editor
.timeline-editor {
  position: relative;
  background-color: #202B33;
  color: #6b7983;
  font-size: 14px;
  overflow-y: scroll;
  min-width: 470px;
  width: 470px;
  flex-grow: 0;
  cursor: pointer;
  height: 100%;
}

.timeline-editor .options-container {
  position: absolute;
  left: 195px;
  top: 0;
  width: 275px;
  opacity: 0;
  transition: opacity .25s;
}

.timeline-editor .options-container.visible {
  opacity: 1;
}

.timeline-editor .options-container > div {
  display: inline-block;
}

.timeline-editor .options-btn {
  margin: 2px 1px 0 4px;
}

.timeline-row {
  height: 44px;
  display: flex;
  line-height: 44px;
  transition: height .25s;
}

.timeline-row.selected {
  height: 80px;
}

.timeline-row.last .step-index, .timeline-row.last .step-character, .timeline-row.last .step-details {
  border-bottom: 1px solid #394B59;
}

.timeline-row .step-index {
  flex-basis: 40px;
  width: 25px;
  padding-left: 15px;
  vertical-align: top;
  overflow: visible;
}

.timeline-row .step-index.filled, .timeline-row .step-character.filled, .timeline-row .step-index.below-filled, .timeline-row .step-character.below-filled {
  border-top: 1px solid #394B59;
}

.timeline-row .step-character {
  flex-basis: 180px;
  font-weight: bold;
  padding: 0 0 0 4px;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: top;
  border-right: 1px solid #394B59;
}

.timeline-row .step-index.primary, .timeline-row .step-character.primary {
  color: white;
  background-color: #182026;
}

.timeline-row .step-details {
  padding: 0;
  position: relative;
  border-top: 1px solid #394B59;
}

.timeline-row.selected .step-details {
  background-color: #182026;
}

.timeline-row .background {
  position: relative;
  width: 275px;
}

.timeline-row .step-details {
  padding: 0;
  position: relative;
}

.timeline-row .editor-preview {
  position: absolute;
  width: 240px;
  transition: opacity .25s;
  opacity: 0;
  pointer-events: none;
}

.editor-preview .delay-cell {
  width: 40px;
  text-align: center;
  display: inline-block;
  border-right: 1px solid #394B59;
}

.timeline-row .editor-preview.visible {
  opacity: 1;
  pointer-events: inherit;
}

.timeline-editor .options-container .info-btn {
  margin-top: 7px;
  margin-left: 210px;
  pointer-events: none;
}

.timeline-editor .options-container .info-btn > span {
  pointer-events: auto;
}

.editor-preview .action-preview, .timeline-editor .action-editor {
  line-height: 28px;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  padding: 7px 2px;
}

.editor-preview .action-preview {
  top: 0;
  left: 8px;
}

.editor-preview.delay .action-preview {
  left: 44px;
}

.action-editor .bp3-form-group {
  margin-bottom: 5px;
}

.timeline-row .action-preview {
  max-width: 260px;
}

 .timeline-editor .action-editor {
   padding: 8px 4px;
   opacity: 0;
   transition: opacity .25s;
   pointer-events: none;
   display: inline-block;
   z-index: 1;
 }

  .timeline-editor .action-editor > div {
    pointer-events: auto;
  }

 .timeline-editor .action-editor select {
   margin-bottom: 4px;
 }

 .timeline-editor .action-editor.visible {
   opacity: 1;
 }*/

/* end timeline editor */

.info-popover {
  padding: 20px 20px;
}

/*.info-popover > div {
   width: 380px;
 }*/

.info-popover .more-btn {
  margin-left: -15px;
}

.info-popover .media-selector {
  margin-bottom: 10px;
}

.info-popover .media-preview {
  width: 280px;
  margin-bottom: 10px;
}

.timeline-row .action-preview img,
.timeline-editor .action-editor img,
.column-editor .action-editor img,
.column-editor .action-preview img,
.media-selector img {
  height: 30px;
  margin: 3px 5px 3px 0;
  vertical-align: middle;
}

.timeline-editor .action-editor .video-thumb,
.editor-preview .video-thumb {
  background-color: #10161a;
  border: 1px solid #202b33;
  width: 44px;
  height: 32px;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
  line-height: 28px;
}

.action-editor .bp3-slider {
  margin: 10px 0;
}

.editor-preview .video-thumb {
  position: absolute;
}

.text-settings {
  padding: 15px 15px 5px 15px;
  width: 380px;
}

.state-settings {
  padding: 15px 15px 5px 15px;
}

.delay-settings {
  padding: 15px 15px 2px 15px;
}

.sequence-timeline div {
  display: inline-block;
}

.sequence-timeline .bp3-button {
  min-height: 25px;
  margin-bottom: 2px;
}

.timeline-block {
  width: 42px;
  height: 32px;
  display: inline-block;
  border: 1px solid #394b59;
  margin-left: -1px;
  margin-bottom: -1px;
  background-color: #202b33;
  text-align: center;
  color: #555;
  font-weight: bold;
  user-select: none;
  cursor: pointer;
  font-size: 10px;
  line-height: 28px;
  letter-spacing: -1px;
  background-size: cover;
  background-position: center;
}

.timeline-block.active {
  background-color: #30404d;
  color: #bbb;
}

.timeline-block.selected {
  border-width: 1px 2px 2px 1px;
  border-color: yellow;
}

.timeline-block:focus {
  outline-offset: -6px;
}

.timeline-label {
  text-align: right;
}

/* Sequence editor */

.sequence-bar {
  width: 100%;
  padding: 5px 0 5px 5px;
  display: flex;
  background: #293742;
  border-bottom: 1px solid #394b59;
}

.sequence-bar.expanded {
  padding-bottom: 0;
}

.sequence-selector {
  padding-right: 5px;
  flex-shrink: 0;
}

.sequence-selector button {
  margin-right: 3px;
}

.sequence-selector button:last-child {
  margin-right: 0;
}

#sequence-select {
  width: 200px;
}

.sequence-bar > .timeline-container {
  width: 100%;
  overflow: auto;
  flex-grow: 1;
  -ms-overflow-style: none;
  max-height: 170px;
  user-select: none;
  border-left: 1px solid #394b59;
}

.sequence-bar > .timeline-container::-webkit-scrollbar {
  display: none;
}

.sequence-timeline {
  white-space: nowrap;
  cursor: pointer;
}

.sequence-timeline.active {
  background-color: #394b59;
}

/*.sequence-editor {
  flex-basis: 450px;
  background-color: #293742;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #394B59;
  /*margin: 5px;
  border-radius: 5px;
  border-top: 1px solid #666;
  border-left: 1px solid #666;
  border-bottom: 1px solid #222;
  border-right: 1px solid #222;*
  user-select: none;
  z-index: 19;
}

.sequence-editor .character-name {
  cursor: pointer;
}

.sequence-editor .bp3-tabs {
  flex-grow: 1;
  height: 0;
}

.sequence-editor .bp3-tab-list {
  /*padding: 5px 10px 0 10px;*
}

.sequence-editor .bp3-tab-panel {
  height: 100%;
  display: flex;
}

.sequence-editor .bp3-panel-stack-view {
  overflow-y: hidden;
}

.sequence-editor-account {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #555;
}*/

.user-account {
  cursor: pointer;
}

.avatar {
  border-radius: 15px;
  height: 30px;
  vertical-align: middle;
}

/*.sequence-editor-account > span:first-child {
  flex-grow: 1;
  line-height: 30px;
}

/*
.sequence-editor-account, .sequence-editor-header, .step-editor, .character-list-editor {
  padding: 10px;
}

.sequence-editor-panel, .character-editor-panel, .location-editor-panel {
  flex-grow: 1;
}*/

.character-info {
  display: flex;
}

.character-header {
  text-align: center;
}

.character-name .bp3-icon {
  color: #5c7080;
}

.character-name {
  margin-top: -3px;
  margin-bottom: 15px;
  font-size: 24px;
}

.character-avatar-large {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin: 3px 10px 0 -10px;
  overflow: hidden;
  color: #5c7080;
}

/*.top-right {
  position: absolute;
  top: 60px;
  right: 10px;
}*/

/* Step editor */

.editor {
  padding: 10px 15px 15px 15px;
  width: 100%;
}

/*.editor-container {
  width: 100%;
}*/

.editor-header {
  /*background-color: #202B33;*/
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  min-height: 28px;
  border-bottom: 1px solid #394b59;
}

.editor-header .header-left {
  flex-grow: 1;
  text-transform: capitalize;
}

.editor-header .header-left .title {
  font-weight: bold;
}

.editor-header .header-left .subtitle {
  color: #738694;
}

.editor-header .header-right {
  flex-grow: 0;
}

.editor-header .bp3-control {
  margin-bottom: 0;
}

.step-editor,
.group-editor,
.editor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  overflow-y: auto;
  user-select: none;
}

.character-button,
.feature-button {
  margin-bottom: 5px;
}

.editor .bp3-tabs,
.editor .bp3-tab-panel {
  width: 100%;
}

.action-editor,
.action-editor .bp3-card {
  width: 100%;
}

.action-editor .title {
  text-transform: capitalize;
}

.action-editor .bp3-non-ideal-state {
  margin-top: -40px;
}

.action-editor .bp3-callout {
  margin-bottom: 10px;
}

.action-description {
  margin-bottom: 10px;
}

.command-select select,
.character-select select {
  font-size: 21px !important;
}

.step-editor .panel-footer,
.group-editor .panel-footer,
.editor .panel-footer {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.body-controls.grid {
  display: flex;
}

.body-controls.maximized {
  flex-grow: 1;
  width: 100%;
}

#content-input {
  margin-bottom: 10px;
}

/* Step badge */

.step-badge {
  margin-right: 10px;
  margin-bottom: 2px;
  user-select: none;
  cursor: pointer;
}

.step-badge .wrapper {
  display: flex;
}

.step-badge .command {
  background-color: #666;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  /*border: 1px solid #ddd;*/
  padding: 2px 4px;
}

.step-badge .content {
  background-color: #888;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  /*border: 1px solid #ddd;*/
  padding: 2px 4px;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content.media {
  padding: 0;
}

.content.utterance {
  background-color: #240f74;
  color: white;
}

.content img {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  max-height: 60px;
}

.option-container {
  display: flex;
  /*align-items: flex-start;*/
  justify-content: space-between;
  flex-wrap: wrap;
}
.option-container > div {
  flex: 40%;
  margin: 5px;
}

#camera-data {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: gray;
  font-size: 10px;
  cursor: pointer;
}

/*.bp3-numeric-input .bp3-input { width: 50px !important; }*/

.swatch {
  margin-top: 2px;
  padding: 4px;
  width: 36px;
  height: 30px;
  background: #eee;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.color {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.popover {
  position: absolute;
  z-index: 1600;
}

.bp3-popover,
.bp3-popover-enter-done {
  z-index: 1600;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.centered-picker {
  position: fixed;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -150px;
  z-index: 3;
}

.character-editor-panel ul,
.location-editor-panel ul,
.sequence-editor-panel ul {
  padding: 0;
  list-style-type: none;
}

li.character-list-item,
li.location-list-item,
li.action-list-item {
  line-height: 24px;
  border-radius: 6px;
  margin: 1px 0;
  padding: 2px 0 2px 8px;
  cursor: pointer;
  user-select: none;
  background-color: #202b33;
}

li.has-substeps {
  background-color: #a66321 !important;
}

li.character-list-item.hidden-char {
  color: #5c7080;
}

li.character-list-item.dragged,
li.action-list-item.dragged {
  cursor: grabbing;
  border: 2px solid #0e5a8a;
}

.editor .control-row {
  border-bottom: 1px solid #394b59;
}

.editor .bp3-slider {
  margin-left: 14px;
  width: calc(100% - 28px);
}

.form-flex-row {
  display: flex;
}

.form-flex-row > div {
}

.form-col {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 6px;
}

.form-col.min {
  margin-right: 8px;
  margin-bottom: 0;
}

.form-col .margin-adjust.bp3-html-select {
  margin-top: -8px;
}

.form-col.pull-right,
.pull-right {
  margin-right: 0;
  float: right;
}

.form-flex-row > div.expand {
  flex-grow: 1;
}

.canvas-container {
  position: absolute;
}

.media-card {
  width: 100px;
  height: 75px;
  border: 1px solid #394b59;
  border-radius: 3px;
  padding: 4px;
  margin: 0 4px 4px 0;
  display: inline-block;
  background-color: #202b33;
  cursor: pointer;
}

.media-card.selected {
  border: solid 1px yellow;
}

.media-card button {
  width: 100%;
  height: 100%;
}

.media-thumbnail {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: black;
}

.button-row,
.control-row {
  display: flex;
  justify-content: space-between;
}

.button-row button {
  height: 100px;
  flex-grow: 1;
  flex-basis: 33%;
  margin: 2px;
}

.button-row.short button {
  height: 60px;
}

.control-column {
  flex-grow: 1;
  margin: 2px;
}

.media-type-select {
  flex: 0 0 110px !important;
}

.add-media-preview {
  background: black;
  height: 30px;
}

.select-media-preview {
  background: black;
  height: 100%;
}

.image-preview {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex: 0 0 60px !important;
}

.video-preview {
  flex: 0 0 60px !important;
}

.audio-preview {
  flex: 0 0 200px !important;
}

.audio-preview audio {
  width: 100%;
  height: 100%;
}

.video-preview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.large-text-area {
  height: 200px !important;
}

.add-media-dialog {
  width: 700px;
}

.input-media {
  margin-bottom: 4px;
}

.close-btn {
  flex: 0 0 40px !important;
}

.search-body {
  min-height: 340px;
}

.media-library.bp3-dialog,
.benefits-dialog.bp3-dialog {
  width: 880px;
}

.connect-dialog.bp3-dialog {
  width: 550px;
}

.media-editor {
  display: flex;
}

.media-table {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-basis: 430px;
  max-height: 420px;
  margin-right: 10px;
  overflow-y: auto;
}

.media-controls-wrapper {
  flex-grow: 1;
  height: 420px;
  overflow-y: auto;
}

.media-controls {
  flex-grow: 1;
}

.media-results-container {
  max-height: 340px;
  overflow-y: auto;
  text-align: center;
}

/*.media-caption {
  border-radius: 4px;
  padding: 5px 8px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  background-color: rgba(50,50,50,.7);
  color: #ccc;
  max-width: 240px;
  text-shadow: 1px 1px #222;
  transition: opacity .5s;
}

.media-caption .caption-title {
  font-weight: bold;
}*/

.simple-input-panel {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 300px;
  height: 70px;
  border-radius: 4px;
  border: 1px solid #333;
  z-index: 1000;
}

.simple-input-panel .bp3-panel-stack-view {
  background-color: rgba(10, 10, 10, 0.7);
  padding: 10px;
  overflow: hidden;
}

.simple-input-panel input {
  border: 1px solid #394b59;
}

.simple-input-logo {
  width: 32px;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ddd;
}

.bp3-card {
  margin-bottom: 10px;
}

.bp3-card h2 {
  margin-top: 0;
}

.card-columns {
  display: flex;
  justify-content: space-between;
}

.card-columns div {
  flex-basis: 48.5%;
}

.auth-code {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 48px;
  text-align: center;
}

.MIDISounds {
  display: none;
}

.music-widget {
  font-family: 'Bravura Text' !important;
}

.delay-index-col .music-widget.note {
  font-size: 16px !important;
}

.music-widget.note {
  font-size: 21px !important;
}

.music-widget.dynamics {
  font-size: 16px !important;
}

.music-widget.bp3-button-group {
  margin: 5px;
}

.music-widget.note.bp3-button,
.music-widget.note .bp3-button {
  padding-top: 20px;
  line-height: 2px;
}

.music-widget.dynamics.bp3-button,
.music-widget.dynamics .bp3-button {
  padding-top: 3px;
  padding-right: 6px;
}

/*.cQMUqH { cursor: pointer; }
.cQMUqH, .cQMUqH .square { border-color: yellow !important; }

.panel-info {
  position: relative;
  display: inline-block;
  font-size: 10px;
  background-color: yellow;
  border-radius: 4px;
  margin: 5px 0 0 5px;
  color: black;
  padding: 5px;
  font-weight: bold;
  letter-spacing: -.5px;
}*/

/*.unselected-panel {
  position: absolute;
  display: inline-block;
}

.unselected-panel:hover {
  border: 1px solid green;
  cursor: pointer;
}*/
